/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable func-style */
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import type { iconIds } from 'atoms/Icons/Icon.types';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Grid from 'quarks/Grid';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Link from 'quarks/Link';
import Paragraph from 'quarks/Paragraph';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import Breadcrumbs from 'molecules/Breadcrumbs/Breadcrumbs';
import Button, { handleButtonTracking } from 'molecules/Button/Button';
import Checkbox from 'molecules/Checkbox/Checkbox';
import Section from 'molecules/Section/Section';
import TextArea from 'molecules/TextArea/TextArea';
import TextField from 'molecules/TextField/TextField';

import type { SwitchbackProps } from 'components/ComponentSwitchback/ComponentSwitchback';
import Feature from 'components/ComponentSwitchback/Feature';
import HeroCopy from 'components/Hero/HeroStandard/HeroCopy';
import type { HeroProps } from 'components/Hero/HeroStandard/heroTypesAndData';

import doodleGenerator from 'utils/doodleGenerator';
import type { DoodleKey } from 'utils/doodleGenerator';
import { getBackgroundIsDark } from 'utils/functions';

import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';

const DemoForm: FC<HeroProps> = ({
  eyebrow,
  heading,
  subheading,
  featureListHeading,
  featureList,
  hsForm,
  leftDoodle,
  rightDoodle,
  sectionId,
  ...props
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const pageURL = props?.builderState?.state?.location?.pathname;
  const pageURLWithoutHyphens = pageURL?.replace(/-/g, ' ');
  const [value, setValue] = React.useState({});
  const [serverResponse, setServerResponse] = React.useState('');
  const backgroundIsDark = getBackgroundIsDark('gray-100');
  const isHero = true;
  const leftDoodleImage = leftDoodle && doodleGenerator(leftDoodle);
  const rightDoodleImage = rightDoodle && doodleGenerator(rightDoodle);
  const doodleProps = {
    position: 'absolute',
    alt: 'doodle',
    display: 'none',
    zIndex: '-1',
    lg: {
      display: 'block',
    },
  } as const;

  type Inputs = {
    firstname: string;
    lastname: string;
    companyname: string;
    phone: string;
    email: string;
    title: string;
    message: string;
    pageURL: string;
    terms: string;
  };
  const schema = yup.object<Inputs>().shape({
    email: yup.string().email().required(),
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    title: yup.string().required(),
    phone: yup.string().required(),
    message: yup.string().required(),
    terms: yup.string().required(),
  });
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema),
  });
  // eslint-disable-next-line no-console
  const onSubmit: SubmitHandler<Inputs> = async data => {
    const response = await fetch('/api/form', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(res => res.json());
    setServerResponse(response);
  };

  // eslint-disable-next-line no-console
  console.log(watch('firstname')); // watch input value by passing the name of it
  interface FeatureProps extends BasicProps {
    title?: string;
    description?: string;
    copy?: string;
    icon?: iconIds;
    backgroundIsDark?: boolean;
    isHero?: boolean;
    link?: string;
  }

  return (
    <Section __typename="ComponentHeroForm" background="primary-25" id={sectionId}>
      <Container position="relative" paddingTop={24}>
        <Container paddingLeft={4} marginBottom={20} md={{ marginBottom: 32 }} xl={{ marginBottom: 58 }}>
          <Breadcrumbs url={pageURLWithoutHyphens} />
        </Container>
        <Grid
          display="block"
          xl={{
            display: 'grid',
            columnGap: '100px',
            gridTemplateColumns: '1fr 570px',
          }}
        >
          <Flex flexDirection="column" gap="32px" position="relative">
            {isHero ? (
              <HeroCopy
                eyebrow={eyebrow}
                heading={heading}
                subheading={subheading}
                backgroundIsDark={backgroundIsDark}
              />
            ) : (
              ''
            )}
            {featureList && (
              <Flex flexDirection="column" gap="24px" xl={{ gap: '32px' }}>
                {featureListHeading && (
                  <Text
                    as="div"
                    textStyle="xl"
                    fontWeight="semiBold"
                    textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
                    md={{ textStyle: 'xxl', fontWeight: 'semiBold' }}
                  >
                    {featureListHeading}
                  </Text>
                )}
                {featureList.map((feature: FeatureProps, index) => (
                  <Feature
                    icon={feature?.icon}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    backgroundIsDark={backgroundIsDark}
                    title={feature?.title}
                    description={feature?.description}
                    copy={feature?.copy}
                    isHero={isHero}
                    link={feature?.link}
                  />
                ))}
              </Flex>
            )}
          </Flex>
          <Container
            marginTop={40}
            position="relative"
            md={{
              marginTop: 64,
            }}
            xl={{
              marginTop: 0,
            }}
            boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
            backgroundColor="common-white"
            paddingAll={32}
          >
            {rightDoodleImage && (
              <Image
                height="205px"
                width="738px"
                top="-145px"
                right="-603px"
                image={rightDoodleImage}
                {...doodleProps}
                xl={{
                  top: '-85px',
                  right: '-730px',
                }}
              />
            )}
            {serverResponse && serverResponse.success ? (
              <>
                <h2>Success</h2>
                <p>Thanks for your interest in Lineup.ai! A representative will reach out shortly.</p>
              </>
            ) : (
              <>
                <div>
                  <Heading as="h2" textStyle="xs" marginBottom={10}>
                    Book a Demo
                  </Heading>
                  <Paragraph
                    textStyle="xl"
                    fontWeight="semiBold"
                    fontSize="textLg"
                    lineHeight="textLg"
                    marginBottom={12}
                  >
                    Enter your information to book a demo.
                  </Paragraph>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <input type="hidden" {...register('pageURL', { value: pageURL })} defaultValue={pageURL} />
                  <Controller
                    name="firstname"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="First Name"
                        required
                        {...field}
                        marginBottom={12}
                        errorMessage={errors.firstname?.message}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        error={errors.firstname?.message ? 'error' : ''}
                      />
                    )}
                  />
                  <Controller
                    name="lastname"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="Last Name"
                        required
                        {...field}
                        marginBottom={12}
                        errorMessage={errors.lastname?.message}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        error={errors.lastname?.message ? 'error' : ''}
                      />
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Company Email"
                        marginBottom={12}
                        required
                        errorMessage={errors.email?.message}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        error={errors.email?.message ? 'error' : ''}
                      />
                    )}
                  />
                  <Controller
                    name="companyname"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Company Name"
                        marginBottom={12}
                        required
                        errorMessage={errors.companyname?.message}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        error={errors.companyname?.message ? 'error' : ''}
                      />
                    )}
                  />
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Title"
                        marginBottom={12}
                        errorMessage={errors.title?.message}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        error={errors.title?.message ? 'error' : ''}
                      />
                    )}
                  />
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Phone"
                        marginBottom={12}
                        required
                        errorMessage={errors.phone?.message}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        error={errors.phone?.message ? 'error' : ''}
                      />
                    )}
                  />

                  <Controller
                    name="message"
                    control={control}
                    render={({ field }) => (
                      <TextArea
                        {...field}
                        label="Message"
                        marginBottom={12}
                        required
                        errorMessage={errors.message?.message}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        error={errors.message?.message ? 'error' : ''}
                      />
                    )}
                  />
                  <Controller
                    name="terms"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        marginBottom={12}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        label={
                          <Text>
                            By submitting you are agreeing to our{' '}
                            <Link textColor="primary-300" href="/terms-and-conditions/">
                              Terms and Conditions.
                            </Link>
                          </Text>
                        }
                        required
                        errorMessage={errors.terms?.message}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        error={errors.terms?.message ? 'error' : ''}
                      />
                    )}
                  />

                  <Button type="submit" iconSize="1.25rem" variant="contained" marginTop={16}>
                    Book a free demo
                  </Button>
                </form>
              </>
            )}
          </Container>
        </Grid>
      </Container>
    </Section>
  );
};
export default DemoForm;
